.shop {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 4rem 0;
    grid-gap: 2rem;
    text-align: center;
}

.shop-wrapper img {
    width: 200px;
    height: 200px;
    border-radius: 10px;
}

.shop-wrapper h5 {
    font-size: 16px;
    line-height: 1.2rem;
    padding-top: 0.5rem;
}

.shop-wrapper p {
    font-size: 16px;
    padding: 0.3rem 0 1.5rem 0;
    font-weight: 600;
}