.product-tabs-wrapper {
    display: flex;
    gap: 1.5rem;
    border-bottom: 2px solid rgb(0 0 0 / 11%);
    align-items: center;
    padding-top: 1.5rem;

}

.product-tabs-wrapper h2::after {
    content: '';
    border-bottom: 2px solid var(--secondary-clr);
    padding-bottom: 0.3rem;
    display: block;

}

.product-tabs-wrapper p {
    cursor: pointer;
    font-weight: 700;
    color: #000;
    padding: 0 0.9rem;
    text-transform: uppercase;
}

.product-tabs-wrapper .active {
    color: var(--secondary-clr);
}

.product-tab-txt {
    display: flex;
}