.cart-hero-wrapper {
    margin: auto;
    text-align: center;
    padding: 5rem 0;
    color: #fff;
    background-position: center;
}

.cart-wrapper {
    display: grid;
    grid-template-columns: 70% 30%;
    gap: 1rem;
}

.cart-data {
    padding: 3rem 0;
}

.cart-table th {
    text-transform: uppercase;
}


.cart-table th,
.cart-table tr td {
    padding: 1rem 2rem;
    border-bottom: 2px solid rgb(0 0 0 / 8%);
    font-size: 20px;

}

.product-img img {
    width: 70px;
    height: 70px;

}


.count-data {
    padding: 0 8px;
}

table svg {
    font-size: 20px;
}

.cart-right-content {
    padding: 2rem 1.5rem;
    border: 3px solid rgb(0 0 0 / 8%);
}

.cart-total {
    padding: 4rem 0 1rem 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid rgb(0 0 0 / 8%);
    margin-bottom: 2rem;

}

.proceed-btn {
    background-color: var(--secondary-clr);
    border: 1px solid var(--secondary-clr);
    padding: 0.8rem 2rem;
    border-radius: 30px;
    color: #fff;
    transition: 0.2s;
    display: block;
    width: 100%;
    text-align: center;

}

.proceed-btn:hover {
    background-color: transparent;
    border: 1px solid var(--secondary-clr);
    color: var(--secondary-clr);

}

.product-remove svg {
    cursor: pointer;
}

.empty-cart {
    text-align: center;
}