.footer-top-bar {
    background-color: var(--primary-clr);
    padding: 1rem 0;
    border-bottom: 2px solid rgba(255, 255, 255, 0.1) !important;
    color: #fff;
}

.footer-top-bar-wrapper {
    max-width: 90%;
    margin: auto;
}

.footer-top-bar-text h5 {
    font-size: 16px;
    padding: 0.3rem 0;
}

.footer-top-bar p {
    color: rgba(255, 255, 255, 0.8);
}

.footer-top-bar-text h4 {
    padding: 0.6rem 0;
}

.footer-top-bar-content {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    gap: 1rem;
}

.footer-body {
    background-color: var(--primary-clr);
    padding: 3rem 0;
}

.footer-body-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 4rem;
    color: #fff;
}

.footer-body-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.footer-body-content a {
    color: #fff;
}

.footer-body-content a:hover {
    color: #cecccc;
    text-decoration: underline;
}

.footer-body-content h4 {
    color: #cecccc;
    padding-bottom: 0.8rem;
}

.footer-body-content input {
    font-size: 16px;
    padding: 1rem;
}

.footer-body-content button {
    margin-top: -1rem;
    font-size: 16px;
    padding: 1rem;
    background-color: var(--secondary-clr);
}

.footer-body-content p {
    color: rgba(255, 255, 255, 0.8);
}

.footer-body-wrapper {
    margin: auto;
    max-width: 90%;
}