.topbar {
    padding: 0.2rem 0;
    background-color: var(--primary-clr);
    border-bottom: 1px solid rgba(58, 77, 105, 1);

}

.topbar-wrapper {
    max-width: 90%;
    margin: auto;

}

.topbar-wrapper,
.navbar-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.topbar-wrapper p {
    color: #fff;

}

.top-bar-icons svg {
    padding: 0 0.5rem;
}

.navbar {
    background-color: var(--primary-clr);
    padding: 1rem 0;
    position: relative;
}

.navbar-wrapper {
    max-width: 90%;
    margin: auto;

}

.navbar-wrapper img {
    max-width: 140px;
    max-height: 140px;

}

.nav-search-content {
    display: flex;
    align-items: center;
    position: relative;
}

.nav-search-content input {
    width: 600px;
    padding: 0.9rem 1.5rem;
    border-radius: 35px 0 0 35px;
    font-size: 16px;
    outline: none;

}

.navbar-product-categories select {
    padding: 1.3rem;
    width: 220px;
    font-size: 16px;
    outline: none;
}

.nav-bar-search svg {
    font-size: 51px;
    line-height: 0;
    color: #fff;
    background-color: var(--secondary-clr);
    padding: 0.5rem;
    border-radius: 0 35px 35px 0;
    position: absolute;
    bottom: 0px;

}

.cart svg {
    color: #fff;
}

.main-navbar-wrapper {
    max-width: 90%;
    margin: auto;
    padding: 1rem 0;
}

.main-navbar-wrapper ul {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
}

.main-navbar-wrapper ul li {
    list-style-type: none;
    padding: 0 1rem;
    font-size: 17px;
    color: black;
    text-transform: uppercase;
    font-weight: 500;
}

.main-navbar-wrapper ul li:hover {
    color: var(--secondary-clr);
    text-decoration: underline;
    transition: all 0.2s;
}

.cart span {
    color: #fff;
    position: absolute;
}

/* ===================== Responsive Navbar ======================= */

.responsive-nav-top,
.responsive-main-nav {
    background-color: #092143;
}

.responsive-nav-top {
    border-bottom: 1px solid rgba(58, 77, 105, 1);
    padding: 0.2rem 0;

}

.responsive-nav-top-wrapper p {
    font-size: 0.8rem;
    color: #fff;
}

.responsive-nav-top-wrapper svg,
.responsive-nav-top-wrapper span {
    color: #fff;
}

.responsive-nav-top-wrapper,
.responsive-main-nav-wrapper {
    max-width: 90%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
}

.responsive-main-nav-wrapper {
    padding: 0.8rem 0;
}

.responsive-main-nav-wrapper .logo img {
    max-width: 100px;
    max-height: 100px;
}

.responsive-nev-links {
    background-color: #fff;
}

.responsive-nev-links ul {
    max-width: 100%;
}

.nav-hide {
    display: none;
}

.show-nav {
    display: block;
}

.responsive-nev-links ul li {
    list-style-type: none;
    padding: 0.3rem 3rem;

}

.responsive-nev-links {
    position: relative;
}

.responsive-nev-links svg {
    color: var(--secondary-clr);
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
}

.responsive-nev-links ul {
    padding-top: 2.5rem;
}