.products-categories-wrapper h2 {
    border-bottom: 2px solid rgb(0 0 0 / 11%);
    padding: 1rem 0;
    position: relative;
}

.products-categories-wrapper h2::after {
    content: '';
    display: block;
    width: 10%;
    border-bottom: 2px solid var(--secondary-clr);
    position: absolute;
    bottom: -2px;

}

.feature-products .swiper {
    width: 100%;
    height: 100%;
}

.feature-products .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 3rem;
}

.feature-products-slides p {
    padding-bottom: 0.6rem;
}

.swiper-slide img {
    display: block;
    width: 190px;
    height: 190px;
    object-fit: cover;
    border-radius: 20px;
}

.swiper-slide img:hover {
    transform: scale(1.04);
    transition: all 0.5s;
}

.feature-products .swiper-button-next,
.feature-products .swiper-button-prev {
    color: #000;
    /* padding: 0 1rem; */
}

.feature-products .swiper-button-next::after,
.feature-products .swiper-button-prev::after {
    font-size: 30px;
}

.swiper-pagination-bullet-active {
    background-color: #000;
}

.feature-products-slides {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.feature-products-slides h5 {
    font-size: 1rem;
    line-height: 1.2rem;
    padding-top: 0.8rem;
}

.feature-products-slides p {
    font-size: 0.9rem;
    line-height: 1.1rem;
    padding: 0.8rem 0 1rem 0;

}