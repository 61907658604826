.product-design-wrapper {
    text-align: center;
}

.product-design-wrapper p {
    padding: 5px 0;
}

.product-design-wrapper img {
    width: 200px;
    height: 200px;
    text-align: center;
}