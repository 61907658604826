.products-category {
    background-color: #f7f7f7;
}

.products-category .swiper {
    width: 100%;
    height: 100%;
    position: relative;
}

.products-category .swiper-slide {
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3rem;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.products-categories-wrapper h2 {
    border-bottom: 2px solid rgb(0 0 0 / 11%);
    padding-bottom: 1rem;
}

.swiper-pagination {
    margin-top: -40px;
}

.products-category .swiper-button-next,
.products-category .swiper-button-prev {
    color: #000;
    padding: 0 2rem;
}

.products-category .swiper-button-next::after,
.products-category .swiper-button-prev::after {
    font-size: 30px;
}

.products-categories-slides:hover {
    transform: scale(1.01);
    transition: 0.5s;
}