* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Cabin', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


/* ============================== Global Styling ============================== */

:root {
  --primary-clr: #092143;
  --secondary-clr: #de961a;
  --text-clr: #777777;
}

.section-spacing {
  max-width: 90%;
  margin: auto;
  padding: 2rem 0;
}

a {
  text-decoration: none;
  color: var(--secondary-clr);
}

h1 {
  font-size: 50px;
  line-height: 60px;
}

h2 {
  font-size: 24px;
  line-height: 34px;
}

h3 {
  font-size: 22px;
  line-height: 30px;
}

h4 {
  font-size: 20px;
  line-height: 28px;
}

h5 {
  font-size: 18px;
  line-height: 26px;
}

h6 {
  font-size: 16px;
  line-height: 24px;
}

p {
  font-size: 16px;
  line-height: 26px;
  color: var(--text-clr);
}

svg {
  font-size: 35px;
}

.btn-primary {
  background-color: var(--secondary-clr);
  padding: 0.5rem 2rem;
  border: 1px solid var(--secondary-clr);
  color: #fff;
  cursor: pointer;
  transition: 0.2s;
  border-radius: 30px;
}

.btn-primary:hover {
  background-color: transparent;
  border: 1px solid var(--secondary-clr);
  color: var(--secondary-clr);
}