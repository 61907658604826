.sale-products {
    position: relative;
    border: 2px solid var(--secondary-clr);
    padding: 2rem;
}

.sale-products-wrapper .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
}

.sale-products-wrapper .swiper-slide img {
    width: 100%;
    height: auto;
}

.sale-products-wrapper .swiper-button-next::after,
.sale-products-wrapper .swiper-button-prev::after {
    color: black;
    font-size: 20px;

}

.sale-products-wrapper h2 {
    padding-bottom: 1.5rem;
}