/* ================= Media Query for 1024 ================ */

@media(max-width:1024px) {

    .hero-slides .swiper-slide {
        padding: 3rem 0 5rem 0;

    }

    .hero-slides h2 {
        font-size: 36px;
        line-height: 1.3em;
        padding: 0.5rem 0 0.3rem 0;
    }

    .hero-slides .slides-content a {
        max-width: 100%;
        bottom: 8%;
        left: 40%;
        display: block;
    }

    .slides-content p {
        padding: 0.2rem;
    }

    .hero-slides .swiper-button-next,
    .hero-slides .swiper-button-prev {
        padding: 0 1rem;
    }

    .products-body-wrapper {
        display: flex;
        flex-direction: column;
    }

    .sale-products-wrapper .swiper-slide img {
        width: 300px;
    }

    .cart-wrapper {

        display: flex;
        flex-direction: column;
    }

    .cart-table {
        overflow-x: scroll;

    }

    .cart-table th,
    .cart-table tr td {
        padding: 1rem 1.3rem;
        font-size: 16px;
    }
}


/* ========================================= Media Query for Mobile ============================== */

@media(max-width:768px) {
    .new-products-tab-wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .product-design-wrapper img,
    .swiper-slide img,
    .shop-wrapper img {
        width: 120px;
        height: 120px;
    }

    .product-tabs-wrapper {
        display: block;
    }

    .product-tabs-wrapper h2::after {
        width: 45%;
    }

    .product-tab-txt {
        padding: 1.7rem 0 0.5rem 0;
        display: flex;
        justify-content: center;
    }

    .products-category .swiper-slide {
        padding: 2rem;
    }

    .products-category .swiper-button-next,
    .products-category .swiper-button-prev {
        padding: 0;
    }

    .products-categories-wrapper h2 {
        padding: 0.5rem 0;
    }

    .products-categories-wrapper h2::after {
        width: 45%;
    }

    .footer-top-bar-content,
    .footer-body-wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1rem;
    }

    .hero-slides .slides-content a {
        bottom: 6%;
        left: 33%;
    }

    .hero-slides h2 {
        font-size: 30px;
    }

    .hero-slides .swiper-slide {
        background-position: center;
        background-size: cover;
    }

    .shop {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        padding: 3rem 0;
        grid-gap: 1.5rem;

    }

    .btn-primary {
        padding: 0.4rem 1rem;
        font-size: 14px;
    }

    .shop-wrapper p {
        padding: 0.3rem 0 1rem 0;
    }

    .cart-table tr td {
        padding: 1rem;
        font-size: 14px;
    }

}